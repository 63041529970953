




import { MetaInfo } from 'vue-meta'
import { Component, Mixins } from 'vue-property-decorator'

import AuthModule from '@/store/modules/auth'
import { AuthToken } from '@/store/types'
import NotifyMixin from '@/mixins/NotifyMixin'

@Component
export default class RegistrationConfirmation extends Mixins(NotifyMixin) {
  private form: AuthToken = {
    token: '',
  }

  private created () {
    if (!this.$route.params.token) {
      this.$router.replace({ name: 'auth.login' })
      this.notifyError('Некорректный токен подтверждения')
    } else {
      this.form.token = this.$route.params.token
      this.handleSubmit()
    }
  }

  private handleSubmit () {
    if (AuthModule.self) {
      AuthModule.logout()
        .then(() => {
          this.registerConfirmation()
        })
        .catch(this.notifyError)
    }
    this.registerConfirmation()
  }

  private registerConfirmation() {
    AuthModule.registrationConfirmation(this.form)
      .then(() => {
        this.notifySuccess('Ваш аккаунт подтвержден')
        this.$router.push({ name: 'profile' })
      })
      .catch(this.notifyError)
  }

  private metaInfo (): MetaInfo {
    return {
      title: 'Подтверждение регистрации',
    }
  }
}
